











































































































































































































import editMixin from "@/mixin/edit-mixin";
import GlobalServices from "@/services/global";
import MODULE from "@/constants/modules";
let global = new GlobalServices();
export default {
  mixins: [editMixin],
  data() {
    return {
      uxModulesList: [], // from api
      // modulesList: [
      //   {displayName: "Dashboard", id: 1},
      //   {displayName: "Sales", id: 2},
      //   {displayName: "Projects", id: 3},
      //   {displayName: "Suppliers", id: 4},
      //   {displayName: "Accounting", id: 5},
      //   {displayName: "Technician", id: 6},
      //   {displayName: "Reports", id: 7},
      //   {displayName: "Inventory", id: 8},
      //   {displayName: "Supplier", id: 9},
      //   {displayName: "Templates", id: 10},
      //   {displayName: "Task", id: 11},
      //   {displayName: "Marketing", id: 12},
      //   {displayName: "SMS", id: 13},
      //   {displayName: "Employees", id: 14},
      //   {displayName: "System", id: 15},
      //   {displayName: "Invoice", id: 16},
      //   {displayName: "Payments", id: 17},
      //   {displayName: "Credits", id: 18},
      // ],
      revenueCategoryList: [
        {displayName: "Overhead Installation", id: 1},
        {displayName: "Dock Equipment Installation", id: 2},
        {displayName: "Construction Installation", id: 3},
        {displayName: "Residential Installation", id: 4},
        {displayName: "Fencing & Gate Installation", id: 5},
        {displayName: "Hollow Metal Installation", id: 6},
        {displayName: "Preventive Maintenance", id: 7},
        {displayName: "Supply only", id: 8},
        {displayName: "Standard", id: 9},
        {displayName: "Service", id: 10},
        {displayName: "Installation", id: 11},
        {displayName: "Maintenance", id: 12},
        {displayName: "Supply", id: 13},
      ],
      typeList: [
        {displayName: "Email Template", id: 14},
        {displayName: "Quote Terms & Condition", id: 15},
        {displayName: "Document Template", id: 5},
      ],
      uxPreferredLanguage: [],
    };
  },
  computed: {
    languageDisplayValue() {
      return this.currentDataObj.details.IntLanguageID ? this.uxPreferredLanguage.find((lang) => lang.id === this.currentDataObj.details.IntLanguageID).displayName : "";
    },
  },
  methods: {
    async initializeData() {
      const uxPreferredLanguage = await global.getUXDropDown(MODULE.CLIENTS.UX.PreferredLanguage);
      this.uxPreferredLanguage = uxPreferredLanguage || [];
    },
    getSelectedDropDownValueOverride(listing, field) {
      let item = null;
      // if (listing === "modules") {
      //   item = this.modulesList.find((data) => {
      //     return data.id === this.currentDataObj.details[field];
      //   });
      // }

      if (listing === "category") {
        item = this.revenueCategoryList.find((data) => {
          return data.id === this.currentDataObj.details[field];
        });
      }

      if (listing === "type") {
        item = this.typeList.find((data) => {
          return data.id === this.currentDataObj.details[field];
        });
      }
      return item;
    },
  },
  mounted() {
    this.initializeData();
  },
  created() {
    this.MODULE = MODULE;
  },
};
