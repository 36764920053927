var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-2 mb-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('b-card',{staticClass:"details"},[_c('div',{staticClass:"header mb-3 d-flex justify-content-between"},[_c('h5',[_vm._v("Template Information")])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Template Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.TypeName),expression:"currentDataObj.details.TypeName"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-edit-template-template-name"},domProps:{"value":(_vm.currentDataObj.details.TypeName)},on:{"keyup":function($event){return _vm.updateField(
                    'TypeName', //PropertyName
                    _vm.currentDataObj.details.TypeName, //Property Value
                    'Template Name', //Display Name,
                    _vm.currentDataObj.details.TypeName, //Display Value
                    'name' //Data object Property Name
                  )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentDataObj.details, "TypeName", $event.target.value)}}})]),_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Module")]),_c('v-select',{attrs:{"options":_vm.UXData.uxModules,"id":"slidepanel-edit-template-module","value":_vm.getSelectedDropDownValue('uxModules', 'IntModuleID'),"clearable":false,"label":"displayName"},on:{"input":function($event){return _vm.onDropdownSelectionChange(
                    $event,
                    'Module', //Display Name
                    'uxModules', //Dropdown List Object
                    'IntModuleID' //Edit Details Property Name
                  )}}})],1),_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Description")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.Notes),expression:"currentDataObj.details.Notes"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-edit-template-description"},domProps:{"value":(_vm.currentDataObj.details.Notes)},on:{"keyup":function($event){return _vm.updateField(
                    'Notes', //PropertyName
                    _vm.currentDataObj.details.Notes, //Property Value
                    'Description', //Display Name,
                    _vm.currentDataObj.details.Notes, //Display Value
                    'notes' //Data object Property Name
                  )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentDataObj.details, "Notes", $event.target.value)}}})]),_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Type")]),_c('v-select',{attrs:{"options":_vm.typeList,"id":"slidepanel-edit-template-type","value":_vm.getSelectedDropDownValueOverride('type', 'IntTemplateTypeID'),"clearable":false,"label":"displayName"},on:{"input":function($event){return _vm.onDropdownSelectionChange(
                    $event,
                    'Type', //Display Name
                    'uxType', //Dropdown List Object
                    'IntTemplateTypeID' //Edit Details Property Name
                  )}}})],1),_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Email Subject")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.Subject),expression:"currentDataObj.details.Subject"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-edit-template-email-subject"},domProps:{"value":(_vm.currentDataObj.details.Subject)},on:{"keyup":function($event){return _vm.updateField(
                    'Subject', //PropertyName
                    _vm.currentDataObj.details.Subject, //Property Value
                    'Email Subject', //Display Name,
                    _vm.currentDataObj.details.Subject, //Display Value
                    'subject' //Data object Property Name
                  )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentDataObj.details, "Subject", $event.target.value)}}})]),_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Category")]),_c('v-select',{attrs:{"options":_vm.UXData.uxCategory,"id":"slidepanel-edit-template-category","value":_vm.getSelectedDropDownValue('uxCategory', 'IntCategoryID'),"clearable":false,"label":"displayName"},on:{"input":function($event){return _vm.onDropdownSelectionChange(
                    $event,
                    'Revenue Category', //Display Name
                    'uxCategory', //Dropdown List Object
                    'IntCategoryID' //Edit Details Property Name
                  )}}})],1),_c('div',{staticClass:"col-md-6 mb-2"},[_c('global-settings-modal',{attrs:{"id":"slidepanel-edit-template-details-language","label":"Language","actionId":_vm.MODULE.CLIENTS.UX.PreferredLanguage}}),_c('v-select',{attrs:{"options":_vm.uxPreferredLanguage,"id":"slidepanel-edit-template-language","label":"displayName","clearable":false,"reduce":function (lang) { return lang.id; }},on:{"input":function($event){return _vm.updateField(
                    'IntLanguageID', //PropertyName
                    _vm.currentDataObj.details.IntLanguageID, //Property Value
                    'Language', //Display Name
                    _vm.languageDisplayValue, //Display Value
                    'IntLanguageID' //Data object Property Name
                  )}},model:{value:(_vm.currentDataObj.details.IntLanguageID),callback:function ($$v) {_vm.$set(_vm.currentDataObj.details, "IntLanguageID", $$v)},expression:"currentDataObj.details.IntLanguageID"}})],1),_c('div',{staticClass:"col-lg-3 col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Unit Price")]),_c('div',{staticClass:"input-group input-with-dollar-sign"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_vm._v("$")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.Amount),expression:"currentDataObj.details.Amount"}],staticClass:"form-control",attrs:{"type":"number","id":"slidepanel-edit-template-unit-price"},domProps:{"value":(_vm.currentDataObj.details.Amount)},on:{"keyup":function($event){return _vm.updateField(
                      'Amount', //PropertyName
                      _vm.currentDataObj.details.Amount, //Property Value
                      'Amount', //Display Name,
                      _vm.currentDataObj.details.Amount, //Display Value
                      'amount' //Data object Property Name
                    )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentDataObj.details, "Amount", $event.target.value)}}})])]),_c('div',{staticClass:"col-lg-3 col-md-6 mb-2"},[_c('b-form-checkbox',{staticClass:"mt-4",attrs:{"id":"chkAutoCCEmailOnQuote","name":"chkAutoCCEmailOnQuote"},on:{"change":function($event){return _vm.checkboxChanged(
                    'isDefault', //PropertyName
                    !_vm.currentDataObj.details.isDefault, //Property Value
                    'Make this the default', //Display Name
                    false
                  )}},model:{value:(_vm.currentDataObj.details.isDefault),callback:function ($$v) {_vm.$set(_vm.currentDataObj.details, "isDefault", $$v)},expression:"currentDataObj.details.isDefault"}},[_vm._v(" Make this the default ")])],1)])])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }